<template>
  <div
    class="register-form"
    @keypress.enter="submit"
  >
    <h4 class="register-form__title">
      Продолжить знакомство со&nbsp;школой
    </h4>
    <h5 class="register-form__subtitle">
      Получите бесплатную консультацию специалиста,
      доступ в личный кабинет, скидку на обучение и подарок
    </h5>

    <form-name-field
      id="register_name_input"
      ref="nameField"
      v-model="name"
      class="register-form__name"
    />

    <form-phone-field
      ref="phoneField"
      class="register-form__phone"
      :errors-list="submitError"
      @updatePhone="phone = $event"
      @clearSubmitError="clearSubmitError"
    />

    <form-email-field
      ref="emailField"
      v-model="email"
      :submit-error="submitError"
      class="register-form__email"
      @clearSubmitError="clearSubmitError"
    />

    <div class="m-btn__pull">
      <button
        id="register_registration_button"
        class="register-form__submit m-btn"
        :disabled="sending"
        type="submit"
        @click="submit"
      >
        Зарегистрироваться
      </button>
    </div>

    <p class="register-form__privacy">
      Нажимая на кнопку, вы соглашаетесь
      <nuxt-link
        to="/privacy"
        target="_blank"
      >
        с условиями обработки данных
      </nuxt-link>
    </p>
  </div>
</template>

<script>
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import Register from '@/mixins/register';

export default {
  name: 'RegisterForm',
  components: {
    FormEmailField,
    FormPhoneField,
    FormNameField,
  },
  mixins: [Register],

  data: () => ({
    landCode: 'school_synergy_ru',
    titleForSend: 'Продолжить знакомство со школой',
  }),
};
</script>

<style lang="scss" scoped>
.register-form {
  margin-top: 30px;
  font-family: 'TildaSans', sans-serif;

  @include media-down(tablet) {
    margin-top: 24px;
  }

  .register-form__title {
    color: $black;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.2px;
    margin-bottom: 10px;

    @include media-down(tablet) {
      font-size: 24px;
      margin-bottom: 8px;
      letter-spacing: 0;
    }
  }

  .register-form__subtitle {
    color: $black;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    max-width: 440px;
    margin: 0 auto 30px;

    @include media-down(tablet) {
      font-size: 20px;
      margin-bottom: 24px;
      font-weight: 600;
      line-height: 100%;
    }
  }

  .register-form__name {
    margin-bottom: 10px;

    @include media-down(tablet) {
      margin-bottom: 8px;
    }
  }

  .register-form__phone {
    margin-bottom: 10px;

    @include media-down(tablet) {
      margin-bottom: 8px;
    }
  }

  .register-form__email {
    margin-bottom: 20px;

    @include media-down(tablet) {
      margin-bottom: 8px;
    }
  }

  .m-btn__pull {
    border-radius: 15px;
    margin-bottom: 10px;

    @include media-down(tablet) {
      margin-bottom: 82px;
    }
  }

  .register-form__privacy {
    color: $black;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;

    @include media-down(tablet) {
      font-weight: 500;
    }

    a {
      color: #73b0f4;
      display: block;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        transition: 200ms;
        height: 2px;
        width: 0;
        background-color: #73b0f4;
      }

      &:hover:after {
        width: 100%;
      }
    }
  }
}
</style>
